import { TMenuCollectionLink, TMenuLink } from './menu.types'

export const MenuCollectionLinks: TMenuCollectionLink[] = [
  {
    title: 'Bestsellers',
    link: '/shop/collection/bestsellers',
    subTitle: 'Our customer favourites',
    image: '/img/menu/bestsellers.jpg'
  },
  {
    isNew: true,
    title: 'Chef in Residence',
    subTitle: 'Melissa Hemsley',
    image: '/img/menu/limited-editions.jpg',
    link: '/shop/collection/melissa-hemsley'
  },
  {
    title: 'The Summer Edit',
    link: '/shop/collection/summer-menu',
    subTitle: 'Seasonal & BBQ Essentials',
    image: '/img/menu/seasonal-menu.jpg'
  },
  {
    title: 'High in Protein',
    link: '/shop/collection/high-in-protein',
    subTitle: 'Packed with plant power',
    image: '/img/menu/high-in-protein.jpg'
  }
]

export const MenuDesktopMealLinks: TMenuLink[] = [
  {
    title: 'Buddha Bowls',
    link: '/shop/category/buddha-bowls'
  },
  {
    title: 'Pastas & Risottos',
    link: '/shop/category/pastas-and-risottos'
  },
  {
    title: 'Curries',
    link: '/shop/category/curries'
  },
  {
    title: 'Noodles & Rice Bowls',
    link: '/shop/category/noodles-and-rice-bowls'
  },
  {
    title: 'Stews',
    link: '/shop/category/stews'
  },
  {
    title: 'Pies & Tarts',
    link: '/shop/category/pies-and-tarts'
  }
]

export const MenuDesktopExtraLinks: TMenuLink[] = [
  {
    title: 'Side Dishes',
    link: '/shop/category/sides'
  },
  {
    title: 'Dessert Pots',
    link: '/shop/category/desserts'
  },
  {
    title: 'Breakfast Pots',
    link: '/shop/category/breakfasts'
  },
  {
    title: 'Smoothies',
    link: '/shop/category/smoothies',
    isNew: true
  }
]
